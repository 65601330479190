<template>
	<div class="newbie">
		<div class="cleverHeader">
			<img class="icon" src="/img/icon-white.png" />
		</div>
		<div
			class="info"
		>Clever Systems is available by invite only and there are no active groups for this user.</div>
		<div
			class="info"
		>If you would like to use Clever Systems, please contact the group owner who referred you.</div>
		<div class="info">If you've been given an invite code, you may accept it below to join.</div>
		<input class="invite-code" type="text" v-model="code" placeholder="Invite code" />
		<button class="btn" @click="acceptInvite()">Accept Invite</button>
		<div class="info">
			<div v-if="error.length>0" class="error">{{error}}</div>
		</div>
		<button class="btn" @click="retry()">Return to Login</button>
		<div class="info">Enjoy your day.</div>
		<div class="info">--M.</div>
	</div>
</template>

<script>
// import { ChoiceRow } from "@/components/ChoiceRow";

export default {
	name: "Basic",
	data() {
		return {
			code: "",
			error: ""
		};
	},
	methods: {
		acceptInvite: async function () {
			// console.log("Accept this....", this.code);
			const res = await this.$services.acceptInvite(this.code);
			if (res.error) {
				this.error = res.error;
				this.$analytics.logEvent(`invite rejected`);
			} else {
				this.$router.push('/choice/next');
			}
		},
		retry: async function () {
			localStorage.removeItem("token");
			this.$services.token = '<none>';
			this.$router.push('/login')
		}
	}
}

</script>

<style scoped>
.newbie {
	text-align: center;
}

.cleverHeader {
	text-align: center;
}

.icon {
	margin-top: 10px;
	width: 30vw;
}

.info {
	margin: 10px;
	text-align: center;
	font-size: 18px;
}

.error {
	color: red;
	margin-bottom: 10px;
}

.invite-code {
	font-size: 18px;
	padding: 5px;
	text-align: center;
	margin: 10px 5px 5px 10px;
	width: 100px;
	color: black;
}

.btn {
	font-size: 18px;
	background-color: black;
	color: #6699ff;
	padding: 5px;
	border: 1px solid #6699ff;
	cursor: pointer;
}
</style>
